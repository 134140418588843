import { FC, useState } from 'react'
import { IconButton } from '@/components/atoms/IconButton'
import { useStores } from '@/utils'
import { observer } from 'mobx-react'
import { IUserBase } from '@/types'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { TransferTokenModal } from '../TransferTokenModal'

type Props = {
  user: IUserBase
  addTransaction: (amount: string, signature: string) => Promise<boolean>
}

export const TransferTokenButton: FC<Props> = observer(({ user, addTransaction }) => {
  const { viewer } = useStores()
  const { publicKey } = useWallet()
  const { connection } = useConnection()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const toAddress = user?.profile?.solanaAddress

  const findATA = async () => {
    if (publicKey && toAddress) {
      await viewer.viewer?.findATAOrUpdateBalance(publicKey, connection)
    }
  }

  const toggleModal = async () => {
    if (!isModalOpen) {
      setIsModalOpen(!isModalOpen)
      await findATA()
    }
  }

  return (
    <>
      <IconButton componentType='button' onClick={toggleModal}>
        <img src='/images/common/aqa_token.png' alt='aqa_token' className='img-fluid' />
      </IconButton>
      <TransferTokenModal
        user={user}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        addTransaction={addTransaction}
      />
    </>
  )
})
