import { observer } from 'mobx-react'
import { FC } from 'react'
import { Field, Form, Formik } from 'formik'
import { SettingButton } from '@/components/atoms/SettingButton'
import * as Yup from 'yup'
import { changeDecimalToString, useStores } from '@/utils'
import { PublicKey } from '@solana/web3.js'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { MessageType } from '@/types'
import styles from './index.module.scss'
import { TokenInputFieldGroup } from '../TokenInputFieldGroup'

type Props = {
  toAddress: string
  fromPublicKey: PublicKey
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  addTransaction: (amount: string, signature: string) => Promise<boolean>
}

type Value = {
  amount: string
}

export const TransferTokenModalForm: FC<Props> = observer(
  ({ toAddress, fromPublicKey, isModalOpen, setIsModalOpen, addTransaction }) => {
    const { sendTransaction } = useWallet()
    const { connection } = useConnection()
    const { messages, viewer } = useStores()
    const balance = viewer.viewer?.tokenBalances[fromPublicKey.toBase58()]
    // トークンを所持していない場合はエラー（balance を取得するからそれから取る）
    // 所持しているトークン以上を入力した場合もエラー
    const validationSchema = Yup.object().shape({
      // TODO: 指数表記を直す処理を他で使う場合 util に移動
      amount: Yup.number()
        .typeError('This field must be a number')
        .positive()
        .max(Number(balance))
        .test('maxDigitsAfterDecimal', 'This field must have 9 digits after decimal or less', (number) => {
          if (!number) {
            return true
          }
          const numFracStr = changeDecimalToString(number)
          return /^\d+(\.\d{0,9})?$/.test(numFracStr)
        })
        .required('Required'),
    })

    const initialValue: Value = {
      amount: '',
    }

    return (
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={async (values: Value, actions) => {
          try {
            const transaction = await viewer.viewer?.transferToken(connection, toAddress, fromPublicKey, values.amount)
            const signature = await sendTransaction(transaction, connection)
            const latestBlockHash = await connection.getLatestBlockhash()
            await connection.confirmTransaction({
              ...latestBlockHash,
              signature,
            })
            if (signature) {
              actions.resetForm()
              messages.add({
                type: MessageType.Info,
                body: 'AQA token transferred',
                isTranslated: true,
                ttl: 5000,
              })
              try {
                await addTransaction(values.amount, signature)
              } catch (e) {
                messages.add({
                  type: MessageType.Error,
                  body: 'Transaction could not be saved.',
                  isDismissable: true,
                  ttl: 5000,
                })
              }
            }
          } catch {
            messages.add({
              type: MessageType.Error,
              body: 'Transfer of AQA token failed',
              isDismissable: true,
              ttl: 5000,
            })
          }
          actions.setSubmitting(false)
          setIsModalOpen(!isModalOpen)
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className={styles.field}>
                <Field name='amount' placeholder='0' balance={balance} component={TokenInputFieldGroup} />
              </div>
              <div className={styles.buttonContainer}>
                <SettingButton
                  isLoading={isSubmitting}
                  disabled={isSubmitting && balance === '0'}
                  buttonSize='medium'
                  buttonType='submit'
                  color='aqa'
                >
                  Transfer
                </SettingButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }
)
