import { FC, FocusEvent, FormEvent } from 'react'
import { FieldProps } from 'formik'
import classNames from 'classnames'
import styles from './index.module.scss'
import { ValidationErrorMessage } from '../../atoms/ValidationErrorMessage'

type Props = {
  placeholder?: string
  color?: 'transparent' | 'background'
  balance?: string
  isToken?: boolean
  readonly?: boolean
  customBlurHandler?: (e: FocusEvent<HTMLInputElement>) => void
  customChangeHandler?: (e: FormEvent<HTMLInputElement>) => void
}

export const TokenInputFieldGroup: FC<Props & FieldProps<never>> = ({
  field,
  form: { touched, errors },
  placeholder = '',
  color = 'transparent',
  balance = '',
  isToken = true,
  readonly = false,
  customBlurHandler,
  customChangeHandler,
}) => {
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e)

    if (customBlurHandler) {
      customBlurHandler(e)
    }
  }

  const container = classNames(styles.inputContainer, {
    [styles.writable]: color === 'background' && !readonly,
    [styles.readonly]: color === 'background' && readonly,
  })

  const cursorClass = classNames(styles.input, {
    [styles.readonly]: readonly,
  })

  return (
    <div className={styles.container}>
      <div className={container}>
        <div className={styles.fields}>
          <input
            className={cursorClass}
            type='text'
            inputMode='decimal'
            pattern='^[0-9]*[.,]?[0-9]*$'
            placeholder={placeholder}
            name={field.name}
            value={field.value}
            onChange={customChangeHandler || field.onChange}
            onBlur={onBlur}
            readOnly={readonly}
          />
          <div className={styles.symbols}>
            <div className={styles.icon}>
              {isToken ? (
                <img src='/images/common/aqa_token.png' alt='aqa_token' />
              ) : (
                <img src='/images/common/sol_icon.png' alt='sol_icon' />
              )}
            </div>
            {isToken ? <span className={styles.symbol}>AQA</span> : <span className={styles.symbol}>SOL</span>}
          </div>
        </div>
        {balance && <div className={styles.balance}>Balance: {balance}</div>}
      </div>
      {!readonly && touched[field.name] && errors[field.name] && (
        <div className={styles.message}>
          <ValidationErrorMessage>{errors[field.name]}</ValidationErrorMessage>
        </div>
      )}
    </div>
  )
}
