import { Avatar } from '@/components/atoms/Avatar'
import { CloseButton } from '@/components/atoms/CloseButton'
import { OutSideOverlay } from '@/components/atoms/OutSideOverlay'
import { TransferTokenModalForm } from '@/components/molecules/TransferTokenModalForm'
import { IUserBase } from '@/types'
import { getUserAvatar, shortSolanaAddress, useStores } from '@/utils'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { observer } from 'mobx-react'
import { FC, useEffect } from 'react'
import ArrowSvg from '@/public/images/common/right_arrow.svg'
import styles from './index.module.scss'

type Props = {
  user: IUserBase
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  addTransaction: (amount: string, signature: string) => Promise<boolean>
}

export const TransferTokenModal: FC<Props> = observer(({ user, isModalOpen, setIsModalOpen, addTransaction }) => {
  const { viewer } = useStores()
  const { publicKey } = useWallet()
  const { connection } = useConnection()
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toAddress = user?.profile?.solanaAddress

  const findATA = async () => {
    if (publicKey && toAddress) {
      await viewer.viewer?.findATAOrUpdateBalance(publicKey, connection)
    }
  }

  useEffect(() => {
    findATA()
  }, [toAddress, publicKey])

  return (
    <div style={isModalOpen ? {} : { display: 'none' }}>
      <OutSideOverlay onClick={toggleModal} isOpen={isModalOpen} backGroundColor='gray' />
      <div className={styles.modal}>
        <div className={styles.card}>
          <div className={styles.heading}>
            <h2>Transfer</h2>
            <div className={styles.closeButton}>
              <CloseButton onClick={toggleModal} isLarge />
            </div>
          </div>
          {!publicKey ||
          !viewer.viewer?.associatedTokenAddresses[publicKey.toBase58()] ||
          !viewer.viewer?.associatedTokenAddresses[publicKey.toBase58()].isExist ||
          !(Number(viewer.viewer?.tokenBalances[publicKey.toBase58()]) > 0) ? (
            <>You don&apos;t have AQA token.</>
          ) : (
            <>
              <div className={styles.fromToContainer}>
                <div className={styles.avatar}>
                  <Avatar src={getUserAvatar(viewer.viewer)} />
                  <span className={styles.address}>{shortSolanaAddress(publicKey.toBase58())}</span>
                </div>
                <div className={styles.arrow}>
                  <ArrowSvg />
                </div>
                <div className={styles.avatar}>
                  <Avatar src={getUserAvatar(user)} />
                  <span className={styles.address}>{shortSolanaAddress(toAddress)}</span>
                </div>
              </div>
              <TransferTokenModalForm
                toAddress={toAddress}
                fromPublicKey={publicKey}
                addTransaction={addTransaction}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
})
